<app-thrive-splash>
  <div fxLayout="row" fxLayoutAlign="center center">
    <app-thrive-card *ngIf="state == openIDState.Loading">
      <mat-card-title-group>
        <mat-card-title>{{'PleaseWait' | translate}}</mat-card-title>
      </mat-card-title-group>
      <mat-card-content>
        <p>{{'LoadingPleaseWait' | translate}}</p>
      </mat-card-content>
    </app-thrive-card>

    <app-thrive-card *ngIf="state == openIDState.Authorised">
      <div fxLayout="row" fxLayoutGap="50px">
        <img fxShow fxHide.lt-md class="status-icon" src="/assets/images/success.svg" alt="{{'Success' | translate}}">
        <div>
          <mat-card-title-group>
            <mat-card-title fxLayout="row" fxLayoutGap="14px" fxLayoutAlign="start center">
              <img fxHide fxShow.lt-md class="status-icon-small" src="/assets/images/success.svg" alt="{{'Success' | translate}}">
              <div>{{'AccountAuthorised' | translate}}</div>
            </mat-card-title>
          </mat-card-title-group>
          <mat-card-content>
            <p>{{'RedirectLink' | translate}}</p>
            <app-thrive-button color="primary" aria-label="Open Thrive" (click)="openLink()">
              <mat-icon>exit_to_app</mat-icon>
              {{'OpenApp' | translate}}
            </app-thrive-button>
          </mat-card-content>
        </div>
      </div>
    </app-thrive-card>

    <app-thrive-card *ngIf="state == openIDState.Error">
      <div fxLayout="row" fxLayoutGap="50px">
        <img fxShow fxHide.lt-md class="status-icon" src="/assets/images/error.svg" alt="{{'Error' | translate}}">
        <div>
          <mat-card-title-group>
            <mat-card-title fxLayout="row" fxLayoutGap="14px" fxLayoutAlign="start center">
              <img fxHide fxShow.lt-md class="status-icon-small" src="/assets/images/error.svg" alt="{{'Error' | translate}}">
              <div>{{'Error' | translate}}</div>
            </mat-card-title>
          </mat-card-title-group>
          <mat-card-content>
            <p>
              {{errorMessage}}
            </p>
            <div fxLayout="row" fxLayoutAlign="end" *ngIf="this.previousState | canGoBack">
              <app-thrive-async-button
                fxFlexAlign="end"
                [action]="backClicked">{{'Back'|translate}}
              </app-thrive-async-button>
            </div>
          </mat-card-content>
        </div>
      </div>
    </app-thrive-card>
  </div>
</app-thrive-splash>
