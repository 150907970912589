import { Injectable } from '@angular/core';
import { PhoneNumberUtil, PhoneNumberFormat, PhoneNumber } from 'google-libphonenumber';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { InternationalCallingCode } from './international-calling-code';
import { AbstractControl, ValidatorFn } from '@angular/forms';

// "require" is supported by webpack - without  declaring it here, the build fails in production mode
declare const require: any;
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));

@Injectable({
  providedIn: 'root'
})
export class PhoneService {

  public static readonly CUSTOM_PHONE_NUMBER: InternationalCallingCode = {
    regionCode: 'CUSTOM',
    countryName: 'Custom',
    diallingPrefix: ''
  };

  private phoneNumberUtil: PhoneNumberUtil;

  constructor() {
    this.phoneNumberUtil = PhoneNumberUtil.getInstance();
  }

  public internationalCallingCodes(): InternationalCallingCode[] {
    const results: InternationalCallingCode[] = [];
    this.phoneNumberUtil.getSupportedRegions().forEach(region => {

      // Some countries from PhoneNumberUtil do not have a corresponding name in i18nIsoCountries (e.g. The Ascension Isles - AC).
      // Only push countries to the results if a name from i18nIsoCountries is provided, otherwise there will be missing country names.
      if (i18nIsoCountries.getName(region, 'en')) {
        const currentCode = this.internationalCallingCodeForRegion(region);
        // Find the index of a pre existing dialling code, if there are none, return -1
        const existingDiallingCodeIndex = results.findIndex(internationalCallingCode => {
          return internationalCallingCode.diallingPrefix === currentCode.diallingPrefix;
        });
        // If dialling code exists, append the country name onto the end of the countryName field
        if (existingDiallingCodeIndex > -1) {
          results[existingDiallingCodeIndex].countryName = `${results[existingDiallingCodeIndex].countryName}, ${currentCode.countryName}`;
        } else {
          results.push(currentCode);
        }
      }

      // Codes are ordered alphabetically by the ISO 3166-1 alpha-2 code, but they don't always tie in with the country name (e.g. 'GB' is
      // 'United Kingdom'). Sorting alphabetically by the country name provides a better user experience.
      results.sort((a, b) => a.countryName.localeCompare(b.countryName));

    });

    // Add "Custom" entry that will override the validation for the phone numbers
    results.push(PhoneService.CUSTOM_PHONE_NUMBER);

    return results;
  }

  public internationalCallingCodeForRegion(region: string): InternationalCallingCode {
    return {
      regionCode: region,
      countryName: this.countryNameForRegion(region),
      diallingPrefix: this.diallingPrefixForRegion(region)
    };
  }

  public validatePhoneNumber(regionCode: () => string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value.length > 0) {
        // If regionCode is "Custom", number should always be valid, so do not return error
        if (regionCode() === PhoneService.CUSTOM_PHONE_NUMBER.regionCode) {
          return null;
        }

        let validNumber = false;
        try {
          const phoneNumber = this.phoneNumberUtil.parseAndKeepRawInput(
            control.value, regionCode()
          );
          validNumber = this.phoneNumberUtil.isValidNumber(phoneNumber);
        } catch (err) { }

        return validNumber ? null : { wrongNumber: { value: control.value } };
      }
    };
  }

  /**
   * Returns the international dialling prefix for a specific region code
   * @param region Region code (e.g. 'GB')
   */
  public diallingPrefixForRegion(region: string): string {
    if (region === PhoneService.CUSTOM_PHONE_NUMBER.regionCode) {
      return PhoneService.CUSTOM_PHONE_NUMBER.countryName;
    } else {
      return `+${this.phoneNumberUtil.getCountryCodeForRegion(region)}`;
    }
  }

  /**
   * Returns the international dialling prefix for an international phone number
   * If number is not valid it returns empty string
   * @param phoneNumber The phone number to deduce the international dialling prefix from
   */
  public diallingPrefixForNumber(phoneNumber: string): string {
    try {
      const convertedPhoneNumber: PhoneNumber = this.phoneNumberUtil.parseAndKeepRawInput(phoneNumber);
      return `+${convertedPhoneNumber.getCountryCode().toString()}`;
    } catch {
      console.warn(`Phone number ${phoneNumber} failed to parse. This could be due to it being a custom phone number. Returning empty string for dialling code.`);
      return PhoneService.CUSTOM_PHONE_NUMBER.diallingPrefix;
    }
  }

  /**
   * Returns the national phone number for an international phone number
   * If number is not valid it returns original non-transformed number back
   * @param phoneNumber The phone number to deduce the international dialling prefix from
   */
  public getNationalNumber(phoneNumber: string): string {
    try {
      const convertedPhoneNumber: PhoneNumber = this.phoneNumberUtil.parseAndKeepRawInput(phoneNumber);
      return convertedPhoneNumber.getNationalNumber().toString();
    } catch {
      console.warn(`Phone number ${phoneNumber} failed to parse. This could be due to it being a custom phone number. Returning original number.`);
      return phoneNumber;
    }
  }

  /**
   * Returns the region code for a specified phone number.
   * If it finds none it returns the predefined CUSTOM region code
   * @param phoneNumber The phone number used to determine the region code
   */
  public getRegionCode(phoneNumber: string): string {
    try {
      const diallingPrefix = this.diallingPrefixForNumber(phoneNumber);
      return this.internationalCallingCodes().find(callingCode => callingCode.diallingPrefix === diallingPrefix).regionCode;
    } catch {
      console.warn(`Failed to find region code for: ${phoneNumber}. This could be due to it being a custom phone number. Returning the predefined Custom Region Code`);
      return PhoneService.CUSTOM_PHONE_NUMBER.regionCode;
    }
  }

  /**
   * Returns the country name in English
   * @param region Region code (e.g. 'GB')
   */
  public countryNameForRegion(region: string): string {
    return i18nIsoCountries.getName(region, 'en');
  }

  /**
   * Converts a local phone number an international phone number (e.g. +441159123456)
   * If parsing fails, we return the original number
   * @param phoneNumber The local phone number (e.g. "0115 9123456")
   * @param regionCode The international region code for where the phoneNumber is (e.g. "GB")
   */
  public convertToInternationalPhoneNumber(phoneNumber: string, regionCode: string): string {
    try {
      const parsedNumber = this.phoneNumberUtil.parse(phoneNumber, regionCode);
      return this.phoneNumberUtil.format(parsedNumber, PhoneNumberFormat.E164);
    } catch {
      console.warn(`Phone number ${phoneNumber} failed to parse. This could be due to it being a custom phone number. Returning original number.`);
      return phoneNumber;
    }
  }
}
